Mosaic.addPlugins([require('/var/www/public/app/design/storefront/satisfly/node_modules/@scandiweb/gtm/src/plugin/config/ConfigReducer.plugin.js'),require('/var/www/public/app/design/storefront/satisfly/node_modules/@scandiweb/gtm/src/plugin/config/ConfigReducer.plugin.js')]);
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    ConfigActionType,
} from './Config.type';

export const MAX_WIDTH = 150;
export const MAX_HEIGHT = 40;
export const DEFAULT_CATGORY_URL_SUFFIX = '.html';

/** @namespace Satisfly/Store/Config/Reducer/filterStoreConfig */
export const filterStoreConfig = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {},
);

export const {
    // @ts-ignore
    countries, reviewRatings, storeConfig, currencyData, currency, cartDisplayConfig, documentTypes,
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    currency: {},
    documentTypes: [],
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false,
    },
};

/** @namespace Satisfly/Store/Config/Reducer/getIndexedRatings */
export const getIndexedRatings = (
    reviewRatings,
) => ((reviewRatings) ? reviewRatings.items || [] : []);

/** @namespace Satisfly/Store/Config/Reducer/getCurrencyRates */
export const getCurrencyRates = (
    base,
    state,
) => (base || state.currency || {});

/** @namespace Satisfly/Store/Config/Reducer/getCurrencyData */
export const getCurrencyData = (
    base,
    state,
) => (base || state.currencyData || {});

/** @namespace Satisfly/Store/Config/Reducer/getCountryData */
export const getCountryData = (
    base,
    state,
) => (base || state.countries || {});

/** @namespace Satisfly/Store/Config/Reducer/getCheckoutAgreementData */
export const getCheckoutAgreementData = (
    base,
    state,
) => (base || state.checkoutAgreements || {});

/** @namespace Satisfly/Store/Config/Reducer/getDocumentTypes */
export const getDocumentTypes = (
    base,
    state,
) => (base || state.documentTypes || []);

/** @namespace Satisfly/Store/Config/Reducer/getInitialState */
export const getInitialState = () => {
    const {
        storeConfig: {
            cms_home_page,
            catalog_default_sort_by,
        } = {},
    } = window;

    return {
        ...filterStoreConfig(storeConfig),
        currencyData,
        currency,
        countries,
        reviewRatings,
        checkoutAgreements: [],
        isLoading: true,
        cartDisplayConfig,
        documentTypes,
        priceTaxDisplay: {
            product_price_display_type: '',
            shipping_price_display_type: '',
        },
        category_url_suffix: DEFAULT_CATGORY_URL_SUFFIX,
        device: {
            isMobile: true,
            android: true,
            ios: false,
            blackberry: false,
            opera: false,
            windows: false,
            safari: false,
            standaloneMode: window.matchMedia('(display-mode: standalone)').matches,
        },
        cms_home_page,
        catalog_default_sort_by,
    };
};

/** @namespace Satisfly/Store/Config/Reducer/ConfigReducer */
// @ts-ignore
export const ConfigReducer = (
    state = getInitialState(),
    action,
) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {},
            documentTypes = [],
        } = {},
        device,
    } = action;

    const { currentCurrency = '' } = action;
    const { currencyData: prevCurrencyData } = state;

    switch (type) {
        case ConfigActionType.UPDATE_CONFIG:
            const filteredStoreConfig = filterStoreConfig(storeConfig);
            const { secure_base_media_url } = filteredStoreConfig;

            window.secure_base_media_url = secure_base_media_url;

            return {
                ...state,
                countries: getCountryData(countries, state),
                reviewRatings: getIndexedRatings(reviewRatings),
                checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
                currency: getCurrencyRates(currency, state),
                currencyData: getCurrencyData(currencyData, state),
                documentTypes: getDocumentTypes(documentTypes, state),
                ...filteredStoreConfig,
                // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
                // and header_logo_src takes old value
                isLoading: false,
                cartDisplayConfig,
            };

        case ConfigActionType.UPDATE_CONFIG_DEVICE:
            return {
                ...state,
                device: {
                    ...state.device,
                    ...device,
                },
            };

        case ConfigActionType.UPDATE_CURRENT_CURRENCY:
            return {
                ...state,
                currencyData: {
                    ...prevCurrencyData,
                    current_currency_code: currentCurrency,
                },
            };

        default:
            return state;
    }
};
export default ConfigReducer;
