/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const UPDATE_DAILY_PRODUCT_PROMO = 'UPDATE_DAILY_PRODUCT_PROMO';
export const UPDATE_BLOG_POST = 'UPDATE_BLOG_POST';

/** @namespace Satisfly/Store/DailyProductPromo/Action/updateDailyProductPromo */
export const updateDailyProductPromo = (dailyProductPromo) => ({
    type: UPDATE_DAILY_PRODUCT_PROMO,
    dailyProductPromo,
});
