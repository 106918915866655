/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_DAILY_PRODUCT_PROMO } from './DailyProductPromo.action';

/** @namespace Satisfly/Store/DailyProductPromo/Reducer/getInitialState */
export const getInitialState = () => ({
    dailyProductPromo: {},
});

/** @namespace Satisfly/Store/DailyProductPromo/Reducer/DailyProductPromoReducer */
export const DailyProductPromoReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_DAILY_PRODUCT_PROMO:
        const { dailyProductPromo } = action;

        return {
            ...state,
            dailyProductPromo,
        };
    default:
        return state;
    }
};

export default DailyProductPromoReducer;
