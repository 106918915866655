/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    LoaderComponent as SourceLoaderComponent,
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace Satisfly/Component/Loader/Component */
export class LoaderComponent extends SourceLoaderComponent {
    renderButtonLoader() {
        return (
            <div block="Loader" elem="Button" />
        );
    }

    render() {
        const { isLoading, mix, isButton } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" mix={ mix } elem="Scale" mods={ { isButton } }>
                    { isButton ? this.renderButtonLoader() : this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
