export * from 'SourceType/Account.type';

export const MyAccountTabs = {
    MY_ACCOUNT: 'my-account',
    MY_ORDERS: 'my-orders',
    MY_ORDER: 'my-order',
    MY_DOWNLOADABLE: 'my-downloadable',
    MY_WISHLIST: 'my-wishlist',
    ADDRESS_BOOK: 'address',
    NEWSLETTER_SUBSCRIPTION: 'newsletter-subscription',
    ACCOUNT_INFORMATION: 'edit',
    MY_INVOICES: 'my-invoices',
    MY_FREQUENTLY_BOUGHT_ITEMS: 'my-frequently-bought-items',
};
