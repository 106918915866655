/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { OverlayActionType } from 'Store/Overlay/Overlay.type';

import { BLOCK_POPUP } from './Popup.action';
import {
    PopupActionType,
} from './Popup.type';

/** @namespace Satisfly/Store/Popup/Reducer/getInitialState */
export const getInitialState = () => ({
    popupPayload: {},
    shouldPopupClose: false,
    payload: {},
    blockedPopup: null,
});

/** @namespace Satisfly/Store/Popup/Reducer/PopupReducer */
export const PopupReducer = (
    state = getInitialState(),
    action,
) => {
    const { payload, type, overlayKey } = action;

    switch (type) {
    case OverlayActionType.TOGGLE_OVERLAY:
    case PopupActionType.SHOW_POPUP:
        return { ...state, popupPayload: payload };
    case OverlayActionType.HIDE_ACTIVE_OVERLAY:
        return { ...state, popupPayload: {} };
    case OverlayActionType.HIDE_ACTIVE_POPUP:
        return { ...state, shouldPopupClose: payload };
    case BLOCK_POPUP:
        return { ...state, blockedPopup: overlayKey };
    default:
        return state;
    }
};

export default PopupReducer;
